import React, { createContext, useState, useEffect, useContext } from "react";
import { Utility } from "../Helpers/utils";
import { AuthService } from "../service/authService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => Utility.getUser());
  const [token, setToken] = useState(() => Utility.getToken());
  const isAuthenticated = !!user && !!token;

  useEffect(() => {
    if (user && token) {
      Utility.setUser(user);
      Utility.setToken(token);
    }
  }, [user, token]);

  const login = async (email, password) => {
    const response = await AuthService.login(email, password);

    if (response.data.success) {
      const userData = response.data.data.user;

      if (!userData.first_login) {
        Utility.setUser(userData);
        Utility.setToken(response.data.data.access_token);
        setUser(userData);
        setToken(response.data.data.access_token);
      }

      return userData;
    } else {
      throw new Error(response.data.message);
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    Utility.unSetUser();
    Utility.unSetToken();
    localStorage.clear();
    sessionStorage.clear();
  };

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
    Utility.setUser(updatedUser);
  };

  return (
    <AuthContext.Provider value={{ user, token, isAuthenticated, login, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
