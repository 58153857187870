import { Field, FieldArray, Formik } from "formik";
import React from "react";
import { ExpensesService } from "../../../../service/ExpenseService";
import { Spinner } from "react-bootstrap";

export const EmergencyCodeTable = ({
  barEmergencyCodes,
  salesReport,
  setBarEmergencyAmount,
  setBarEmergencyCodeCreated,
  setBarEmergencyCodeDetails,
}) => (
  <div
    className="row"
    style={{
      marginBottom: "10px",
      marginTop: "10px",
    }}
  >
    <Formik
      initialValues={{
        emergencyCodes: barEmergencyCodes ? barEmergencyCodes : [],
        report_date: salesReport ? salesReport.report_date : "",
        owner: salesReport ? salesReport.owner : "",
      }}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(true);

        ExpensesService.createEmergencyExpenses(values)
          .then((response) => {
            console.log(response.data.data);
            let barEmergency = response.data.data.filter((item) => {
              return item.job_code === "Bartender";
            });
            setSubmitting(false);
            if (response.data.data.length > 0) {
              setBarEmergencyAmount(response.data.data[0].tips_owed);
              setBarEmergencyCodeCreated(true);
            }
            setBarEmergencyCodeDetails(barEmergency);
          })
          .catch((error) => {
            setSubmitting(false);
            console.log(error);
          });
      }}
    >
      {({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => (
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <div>
              <h4>Emergency Code(s) </h4>
              <span>
                <small id="emailHelp" className="form-text text-muted">
                  Emergency Code Details
                </small>
              </span>
            </div>
            <div className="table-responsive" style={{ marginTop: "30px" }}>
              <FieldArray name="barEmergencyCodes">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { emergencyCodes } = values;

                  return (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}> Code </th>
                          {/* <th className="table-wider"> Hours </th> */}
                          <th style={{ width: "5%" }}> Hours </th>
                          <th style={{ width: "10%" }}> Tipshare </th>
                          <th style={{ width: "10%" }}> Tips Owed </th>

                          <th style={{ width: "20%" }}> Employee Name </th>
                          <th style={{ width: "15%" }}> Base Location </th>
                        </tr>
                      </thead>
                      <tbody>
                        {emergencyCodes.map((code, index) => (
                          <tr key={code.emergency_code} id={"emergency_codes_row_" + index}>
                            <td
                              style={{
                                paddingLeft: "0px",
                              }}
                            >
                              <Field
                                name={`emergencyCodes[${index}].emergency_code`}
                                className="form-control"
                                placeholder="Emergency Code"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                                value={code.emergency_code}
                                style={{
                                  border: "none",
                                  paddingLeft: "10px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>

                            <td
                              style={{
                                paddingLeft: "0px",
                              }}
                            >
                              <Field
                                name={`emergencyCodes[${index}].totalHours`}
                                className="form-control"
                                placeholder="Total Hours"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={"disabled"}
                                value={code.totalHours}
                                style={{
                                  border: "none",
                                  paddingLeft: "10px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>

                            <td
                              className="amountRow"
                              style={{
                                paddingLeft: "0px",
                              }}
                            >
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <Field
                                  name={`emergencyCodes[${index}].tipshare`}
                                  className="form-control"
                                  placeholder="Tips Owed"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  disabled={"disabled"}
                                  value={code.tipsOwed}
                                  style={{
                                    border: "none",
                                    paddingLeft: "10px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </div>
                            </td>

                            <td
                              className="amountRow"
                              style={{
                                paddingLeft: "0px",
                              }}
                            >
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">$</span>
                                </div>
                                <Field
                                  name={`emergencyCodes[${index}].tips_owed`}
                                  className="form-control"
                                  placeholder="Tips Owed"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  autoCapitalize="off"
                                  spellCheck="false"
                                  disabled={isSubmitting}
                                  defaultValue={code.tips_owed}
                                  style={{
                                    border: "none",
                                    paddingLeft: "10px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </div>
                            </td>

                            <td
                              style={{
                                paddingLeft: "5px",
                              }}
                            >
                              <Field
                                name={`emergencyCodes[${index}].employee_name`}
                                className="form-control"
                                placeholder="Employee Name"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={isSubmitting}
                                defaultValue={code.employee_name ? code.employee_name : ""}
                              />
                            </td>

                            <td
                              style={{
                                paddingLeft: "5px",
                              }}
                            >
                              <Field
                                as="select"
                                name={`emergencyCodes[${index}].base_location`}
                                className="form-control"
                                placeholder="Base location"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                disabled={isSubmitting}
                                defaultValue={code.base_location ? code.base_location : "DEFAULT"}
                              >
                                <option value="DEFAULT" disabled>
                                  Select One
                                </option>
                                <option value="1"> La Parrilla (Marietta Square) </option>
                                <option value="2"> La Parrilla (Dallas Hwy) </option>
                                <option value="3"> La Parrilla (Flowery Branch) </option>
                                <option value="4"> La Parrilla (Powder Spring) </option>
                                <option value="5"> La Parrilla (Fayette Ville) </option>
                                <option value="6"> La Parrilla (Alpharetta) </option>
                                <option value="7"> La Parrilla (Woodstock) </option>
                                <option value="9"> La Parrilla (Acworth) </option>
                                <option value="10"> La Parrilla (Newman) </option>
                                <option value="11"> La Parrilla (Mcdonough) </option>
                                <option value="12"> La Parrilla (Howel Mill) </option>
                                <option value="13"> La Parrilla (Dothan) </option>
                                <option value="14"> La Parrilla (Norcross) </option>
                                <option value="15"> La Parrilla (Canton) </option>
                                <option value="16"> La Parrilla (Macon) </option>
                                <option value="17"> La Parrilla (Greenville) </option>
                                <option value="18"> La Parrilla (Toco Hill) </option>
                                <option value="19"> La Parrilla (Savannah) </option>
                                <option value="20"> La Parrilla (Griffin) </option>
                                <option value="21"> La Parrilla (Rome) </option>
                              </Field>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  );
                }}
              </FieldArray>
            </div>

            <div className="col-md-12" style={{ marginTop: "20px" }}>
              <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                Submit Emergency Code &nbsp;&nbsp;
                {isSubmitting ? <Spinner animation="border" size="sm" /> : ""}
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  </div>
);
